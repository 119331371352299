import { useEffect, useState } from 'react';
import { FNCVariantAvailability } from 'shared/types/furniturechoice/variant';
import { sdk } from '~/sdk';

export default function useCartAvailability(skus: string[]) {
  const [isOnStock, setIsOnStock] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getCartAvailability() {
      const responses = await Promise.all(
        skus.map((sku) =>
          sdk.callAction<FNCVariantAvailability>({
            actionName: 'furniturechoice-inventory/getVariantAvailability',
            payload: { sku },
            skipQueue: true,
          }),
        ),
      );

      setIsOnStock(
        responses.every((response) => {
          if (response.isError) {
            throw response.error;
          }

          return response.data.isOnStock;
        }),
      );
      setIsLoading(false);
    }

    getCartAvailability();
  }, [skus]);

  return { isOnStock, isLoading };
}
