'use client';

import Link from '~/components/furniturechoice/link';
import { TasticProps } from '~/frontastic/tastics/types';
import SocialMediaIcon from './social-media-icon';

export interface SocialProps {
  fb_link: string;
  fb_show: boolean;
  pin_link: string;
  pin_show: boolean;
  tiktok_link: string;
  tiktok_show: boolean;
  ig_link: string;
  ig_show: boolean;
  x_link: string;
  x_show: boolean;
}

interface iSocialAttr {
  title: string;
  alt: string;
  ga4_event_name: string;
  ga4_event_param_1_name: string;
  ga4_event_param_1_value: string;
  href: string;
}

export default function SocialMediaUtilityBar({ data }: TasticProps<SocialProps>) {
  const socialAttrs: iSocialAttr[] = [
    {
      title: 'Facebook',
      alt: 'FB',
      ga4_event_name: 'navigation_footer',
      ga4_event_param_1_name: 'element',
      ga4_event_param_1_value: 'Facebook',
      href: data.fb_link,
    },
    {
      title: 'Pinterest',
      alt: 'Pinterest',
      ga4_event_name: 'navigation_footer',
      ga4_event_param_1_name: 'element',
      ga4_event_param_1_value: 'Pinterest',
      href: data.pin_link,
    },
    {
      title: 'Tiktok',
      alt: 'Tiktok',
      ga4_event_name: 'navigation_footer',
      ga4_event_param_1_name: 'element',
      ga4_event_param_1_value: 'Tiktok',
      href: data.tiktok_link,
    },
    {
      title: 'Instagram',
      alt: 'IG',
      ga4_event_name: 'navigation_footer',
      ga4_event_param_1_name: 'element',
      ga4_event_param_1_value: 'Instagram',
      href: data.ig_link,
    },
    {
      title: 'Twitter',
      alt: 'Twitter',
      ga4_event_name: 'navigation_footer',
      ga4_event_param_1_name: 'element',
      ga4_event_param_1_value: 'Twitter',
      href: data.x_link,
    },
  ];

  const displaySocial = (title: string) => {
    switch (title) {
      case 'Facebook':
        return data.fb_show;
      case 'Pinterest':
        return data.pin_show;
      case 'Tiktok':
        return data.tiktok_show;
      case 'Instagram':
        return data.ig_show;
      case 'Twitter':
        return data.x_show;
    }
  };

  return (
    <div className="col-span-4 py-8">
      <div className="mb-2 text-center text-20 font-semibold">Follow us on social</div>
      <ul id="social-media-utility-bar" data-testid="social-icons" className="flex flex-wrap justify-center gap-3">
        {socialAttrs
          .filter((attr) => displaySocial(attr.title))
          .map((social) => (
            <li key={social.title} className="hover:opacity-60">
              <Link
                data-testid="social-links"
                reference={{
                  type: 'link',
                  link: social.href,
                  target: '_blank',
                  openInNewWindow: true,
                }}
                ga4_event_name={social.ga4_event_name}
                ga4_event_param_1_name={social.ga4_event_param_1_name}
                ga4_event_param_1_value={social.ga4_event_param_1_value}
              >
                <SocialMediaIcon type={social.title} />
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}
