'use client';

import { useSearchParams } from 'next/navigation';
import { MdPassword } from 'react-icons/md';
import { useFormat } from '~/helpers/hooks/useFormat';
import Form from './form';
import Header from './header';

export default function VerificationContentManager() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const searchParam = useSearchParams();
  const token = searchParam.get('token') ?? '';

  return (
    <section className="standard-section-padding">
      <Header
        contentHeadline={formatMessage({ id: 'tastics.account.verification-content-manager.set_password' })}
        contentHeadlineIcon={<MdPassword />}
      />

      <div className="container flex flex-col gap-y-10">
        <Form token={token} />
      </div>
    </section>
  );
}
