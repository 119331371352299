import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/account/account-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/account/change-password-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/account/details-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/account/order-detail-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/account/orders-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/account/sign-in-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/account/verification-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/cart/cart-usp/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/cart/content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/category/collection/collection-header/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/category/collection/collection-variation-sliders/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/category/plp/plp-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/checkout-header/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/customer/customer-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/information/information-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/order-confirmation/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/payment-notification/paypal/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/payment-notification/v12/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/payment/payment-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/checkout/shipping/shipping-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/article-highlights/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/contentful-snippet/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/divider/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/expandable-text-area/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/featured-in-highlights/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/hero-banner/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/hero-carousel/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/image-highlight/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/inspiration-article-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/mailchimp-newsletter/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/promo-banner/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/promo-banner/social-media-utility-bar/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/recently-viewed-product-slider/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/sticky-mailchimp-newsletter/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/text-body/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/trustpilot/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/content/usp-highlight/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/core/finance-calculator/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/core/minicart/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/core/noindex/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/core/site-footer/footnotes/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/core/site-footer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/core/site-header/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/product/content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/search/search-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/test/test-example/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/frontastic/tastics/furniturechoice/wishlist/wishlist-content-manager/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/onlinestore/frontend/providers/index.tsx")