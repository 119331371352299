'use client';

import { TasticProps } from '~/frontastic/tastics/types';

export enum GapSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

interface DividerProps {
  gapSize: GapSize;
}

export default function Divider({ data }: TasticProps<DividerProps>) {
  let marginClass = 'my-0';
  switch (data.gapSize) {
    case GapSize.S:
      marginClass = 'my-1';
      break;
    case GapSize.M:
      marginClass = 'my-2';
      break;
    case GapSize.L:
      marginClass = 'my-3';
      break;
    case GapSize.XL:
      marginClass = 'my-4';
      break;
  }
  return <div className={'container border-b-2 border-grey-3 ' + marginClass} data-testid="divider" />;
}
