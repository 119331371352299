import { useContext } from 'react';
import { FNCProductPaginatedResult } from 'shared/types/result/PaginatedResult';
import useSWR, { SWRResponse } from 'swr';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';

interface UseProductArgs {
  skus: string[];
}

export default function useProducts({ skus }: UseProductArgs): SWRResponse<FNCProductPaginatedResult> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  return useSWR<FNCProductPaginatedResult>(
    skus.length > 0 ? `/action/furniturechoice-product/getProductsBySku/${skus.sort().join(',')}` : null,
    async () => {
      return await executeWithProgress<FNCProductPaginatedResult>(async () => {
        const response = await sdk.callAction<FNCProductPaginatedResult>({
          actionName: 'furniturechoice-product/getProductsBySku',
          payload: { skus },
          skipQueue: true,
        });

        if (response.isError) {
          throw response.error;
        }

        return response.data;
      });
    },
  );
}
