import { FormEvent, useContext, useState } from 'react';
import { useRouter } from 'next/navigation';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import Link from '~/components/furniturechoice/link';
import ModalDialog from '~/components/furniturechoice/modal-dialog';
import SectionHeader from '~/components/furniturechoice/section-header';
import { useAccount } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';

interface FormProps {
  accountManager: ReturnType<typeof useAccount>;
}

export default function Form({ accountManager }: FormProps): JSX.Element {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress, navigateWithProgress } = useContext(ProgressIndicatorContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData) as { email: string; password: string };
    const result = await executeWithProgress(() => accountManager.login(payload.email, payload.password));
    if (result.accountId) {
      navigateWithProgress(() => router.push('/account'));
    } else {
      setErrorMessage(formatMessage({ id: 'tastics.account.sign-in-content-manager.invalid_username_or_password' }));
      setDialogOpen(true);
    }
  }

  return (
    <>
      <form
        className="container mx-auto flex flex-col justify-center gap-y-10 lg:max-w-[478px]"
        onSubmit={handleSubmit}
      >
        <SectionHeader as="h1">
          {formatMessage({ id: 'tastics.account.sign-in-content-manager.sign_in' })}
        </SectionHeader>

        <div className="flex flex-col gap-y-3">
          <FNCInput
            type="email"
            name="email"
            label={formatMessage({ id: 'tastics.account.sign-in-content-manager.email' })}
            required
          />
          <FNCInput
            type="password"
            name="password"
            label={formatMessage({ id: 'tastics.account.sign-in-content-manager.password' })}
            required
          />

          <Link className="block text-right text-11 text-salmon-4" href="/forgot-password">
            {formatMessage({ id: 'tastics.account.sign-in-content-manager.forgot_your_password' })}
          </Link>

          <FNCButton className="surface-green-2 rounded-md" type="submit">
            {formatMessage({ id: 'tastics.account.sign-in-content-manager.sign_in' })}
          </FNCButton>
        </div>
      </form>
      <ModalDialog
        isOpen={dialogOpen}
        onModalClose={() => {
          setDialogOpen(false);
        }}
        header={errorMessage as string}
        description=""
      />
    </>
  );
}
