'use client';

import { useRef } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { SwiperContainer } from 'swiper/element/bundle';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import useSwiper from '~/helpers/hooks/furniturechoice/use-swiper';

export interface UspItemProps {
  tileImage: ImageProps;
  uspHeadline: string;
  uspSubheader: string;
  uspLink: string;
}

export interface UspHighlightProps {
  uspItem: UspItemProps[];
}

export default function UspHighlight({ data }: TasticProps<UspHighlightProps>) {
  const ref = useRef<SwiperContainer>(null);

  useSwiper({
    ref,
    props: {
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 5,
        },
      },
    },
  });

  return (
    <swiper-container
      ref={ref}
      // @ts-ignore class="container my-8"
      class="container my-8"
      autoplay-delay="3000"
      autoplay-disable-on-interaction="true"
      loop
    >
      {data.uspItem.map((item, index) => (
        <swiper-slide key={index}>
          <Link
            href={item.uspLink}
            className="my-4 flex items-center justify-center"
            data-testid="usp-item"
            ga4_event_name="usp_click"
            ga4_event_param_1_name="element"
            ga4_event_param_1_value={item.uspHeadline}
          >
            <CoFeImage asset={item.tileImage.media} className="w-[40px]"></CoFeImage>
            <div className="px-2 text-12 text-grey-5">
              <div className="font-semibold">{item.uspHeadline}</div>
              <div className="mt-1 flex items-start">
                <span>{item.uspSubheader}</span>
                <MdChevronRight className="fill-grey-7 text-14" />
              </div>
            </div>
          </Link>
        </swiper-slide>
      ))}
    </swiper-container>
  );
}
