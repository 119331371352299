import { HTMLAttributes, useContext } from 'react';
import { useRouter } from 'next/navigation';
import { MdLogout } from 'react-icons/md';
import { useAccount } from '~/frontastic';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function AccountSignout({ ...props }: HTMLAttributes<HTMLButtonElement>) {
  const router = useRouter();
  const accountManager = useAccount();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress, navigateWithProgress } = useContext(ProgressIndicatorContext);

  async function handleLogoutClick() {
    await executeWithProgress(() => accountManager.logout());
    navigateWithProgress(() => router.push('/'));
  }

  return (
    <button data-testid="logout-button" type="button" onClick={handleLogoutClick} {...props}>
      <MdLogout />
      {formatMessage({ id: 'components.account-welcome-header.sign_out' })}
    </button>
  );
}
