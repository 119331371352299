'use client';

import { useRef, useState, useEffect, useId } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { SwiperContainer } from 'swiper/element/bundle';
import Link from '~/components/furniturechoice/link';
import SectionHeader from '~/components/furniturechoice/section-header';
import VariantCardPricing from '~/components/furniturechoice/variant-card-pricing';
import VariantMainImage from '~/components/furniturechoice/variant-main-image';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import useProducts from '~/helpers/hooks/furniturechoice/use-products';
import useSwiper from '~/helpers/hooks/furniturechoice/use-swiper';
import { useFormat } from '~/helpers/hooks/useFormat';
import WishlistButton from '../../product/content-manager/main-details/wishlist-button';

export default function RecentlyViewedProductSlider(): JSX.Element {
  const formatSkus = (SKUString: string): string[] => JSON.parse(SKUString);
  const ref = useRef<SwiperContainer>(null);
  const { locale } = useParams();
  const [recentSKUs, setRecentSKUs] = useState<string>('[]');
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { data, isLoading } = useProducts({ skus: formatSkus(recentSKUs) });
  const prevButtonId = useId();
  const nextButtonId = useId();

  useSwiper({
    ref,
    props: {
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16,
          navigation: { enabled: false },
          scrollbar: { enabled: true },
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 32,
          navigation: {
            prevEl: `#${CSS.escape(prevButtonId)}`,
            nextEl: `#${CSS.escape(nextButtonId)}`,
            enabled: true,
          },
          scrollbar: { enabled: true },
        },
      },
    },
  });

  useEffect(() => {
    const fetchRecentProducts = async () => {
      const localStorageSKUs = localStorage.getItem('recentSKUs') || '[]';
      if (localStorageSKUs == '[]') return;

      setRecentSKUs(localStorageSKUs);
    };

    fetchRecentProducts();
  }, []);

  if (isLoading) return <div className="standard-section-padding" />;

  if (!data) return <></>;

  const sortedData = formatSkus(recentSKUs)
    .reverse()
    .map((sku) => data.items.find(({ selectedVariant }) => selectedVariant.sku == sku))
    .filter((v) => typeof v != 'undefined');

  return (
    <section className="standard-section-padding">
      <div className="container">
        <SectionHeader as="h2">
          {formatMessage({ id: 'tastics.content.recently-viewed-product-slider.recently_viewed' })}
        </SectionHeader>

        <div className="hidden justify-end lg:flex lg:gap-x-2">
          <button
            id={prevButtonId}
            className="surface-salmon-4 flex aspect-square h-7 w-7 items-center justify-center rounded"
          >
            <MdChevronLeft size={24} />
          </button>
          <button
            id={nextButtonId}
            className="surface-salmon-4 flex aspect-square h-7 w-7 items-center justify-center rounded"
          >
            <MdChevronRight size={24} />
          </button>
        </div>

        <swiper-container
          // @ts-ignore: React doesn't support className for web components
          // eslint-disable-next-line tailwindcss/classnames-order
          class={clsx(
            'mt-10 flex overflow-x-hidden lg:mt-5',
            // eslint-disable-next-line prettier/prettier
            '[--swiper-scrollbar-bg-color:#e6e6e6] [--swiper-scrollbar-bottom:calc(0px)] [--swiper-scrollbar-drag-bg-color:black] [--swiper-scrollbar-sides-offset:0px] [--swiper-scrollbar-size:3px]',
          )}
          data-testid="recently-viewed-swiper"
          ref={ref}
          init={false}
          scrollbar
        >
          {sortedData.map((product) => {
            if (!product) return <></>;
            const selectedVariant = product.selectedVariant;
            return (
              <swiper-slide
                // @ts-ignore: React doesn't support className for web components
                class="relative mb-4"
                data-testid="recently-viewed-slide-image"
                key={selectedVariant.sku}
                role="figure"
              >
                <div className="absolute right-0 top-0">
                  <WishlistButton product={product} type="card" />
                </div>
                <Link
                  ga4_event_name="user_interaction"
                  ga4_event_param_1_name="interaction_type"
                  ga4_event_param_1_value="Recently Viewed Products"
                  ga4_event_param_2_name="element"
                  ga4_event_param_2_value={'/p/' + selectedVariant.slug}
                  href={'/p/' + selectedVariant.slug}
                  title={selectedVariant.metafields.productName}
                >
                  {selectedVariant.discountedPrice && (
                    <div className="absolute left-0 top-0 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-red-2">
                      <span className="text-center text-10 text-white">
                        {CurrencyHelpers.formatForCurrency(
                          {
                            fractionDigits: 0,
                            centAmount:
                              (selectedVariant.price?.centAmount || 0) -
                              (selectedVariant.discountedPrice.centAmount || 0),
                            currencyCode: selectedVariant.price?.currencyCode,
                          },
                          locale,
                        )}
                        <br />
                        {formatMessage({ id: 'tastics.content.recently-viewed-product-slider.off' })}
                      </span>
                    </div>
                  )}

                  <VariantMainImage
                    className="aspect-square w-full rounded-md object-cover"
                    sku={selectedVariant.sku}
                    lazyLoadInView
                    colSpanPercent={0.5}
                    colSpanPercentLg={0.25}
                  />

                  <VariantCardPricing
                    className="mt-3 flex flex-col items-baseline gap-y-3 lg:flex-row lg:gap-x-1"
                    variant={selectedVariant}
                    locale={locale}
                  />
                </Link>
              </swiper-slide>
            );
          })}
        </swiper-container>
      </div>
    </section>
  );
}
