import { LineItem } from 'shared/types/wishlist';
import { UseCartReturn } from '~/frontastic/hooks/useCart/types';
import { UseWishlist } from '~/frontastic/hooks/useWishlist/types';
import WishlistCard from './wishlist-card';
import WishlistEmpty from '../wishlist-empty';

interface WishlistGridProps {
  lineItems: LineItem[];
  cartManager: UseCartReturn;
  wishlistManager: UseWishlist;
}

export default function WishlistGrid({ lineItems, cartManager, wishlistManager }: WishlistGridProps): JSX.Element {
  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {lineItems.map((lineItem) => (
        <WishlistCard
          key={lineItem.lineItemId}
          lineItem={lineItem}
          cartManager={cartManager}
          wishlistManager={wishlistManager}
        />
      ))}

      {lineItems.length === 0 && <WishlistEmpty />}
    </div>
  );
}
