import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Account } from 'shared/types/account';
import { useFormat } from '~/helpers/hooks/useFormat';
import AccountSignout from '../account-signout';

interface AccountWelcomeHeader extends HTMLAttributes<HTMLDivElement> {
  account: Account;
}

export default function AccountWelcomeHeader({ className, account, ...props }: AccountWelcomeHeader): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <header className={clsx('flex flex-col gap-y-4 border-b border-solid border-grey-3 py-4', className)} {...props}>
      <h1 className="text-center font-sans text-24 font-bold lg:text-left">
        {formatMessage({ id: 'components.account-welcome-header.my_account' })}
      </h1>

      <div className="flex items-center">
        <p className="flex-1 text-center text-18 leading-6 lg:text-left">
          <span>{formatMessage({ id: 'components.account-welcome-header.welcome_back' })}</span>{' '}
          <span className="font-bold" data-testid="full-name">
            {`${account.salutation ?? ''} ${account.firstName} ${account.lastName}`}
          </span>
        </p>

        <AccountSignout className="ml-auto hidden items-center gap-x-1 text-grey-5 lg:flex" />
      </div>
    </header>
  );
}
