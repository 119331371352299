import { useEffect, useRef } from 'react';
import { sdk } from '~/sdk';
import { useCookieConsent } from '~/utils/use-cookie-consent';

interface TrackCategoryViewPayload {
  categoryId: string;
}

async function trackCategoryView(payload: TrackCategoryViewPayload) {
  return await sdk.callAction<{ ok: boolean }>({
    actionName: 'furniturechoice-relewise/trackCategoryView',
    payload: payload,
  });
}

const useTrackCategoryView = (categoryId: string | undefined) => {
  const { functionalConsent } = useCookieConsent();
  const prevCategoryid = useRef<string | null>(null);

  useEffect(() => {
    // Prevent tracking if no consent is given
    if (!functionalConsent) {
      return;
    }
    // Prevent tracking if categoryId is not available
    if (!categoryId) {
      return;
    }
    // Prevent duplicate tracking
    if (prevCategoryid.current === categoryId) {
      return;
    }
    // Track category view asynchronously
    trackCategoryView({
      categoryId,
    });

    prevCategoryid.current = categoryId;
  }, [categoryId, functionalConsent]);
};

export default useTrackCategoryView;
