import { MdOutlineLocationOn, MdPhoneIphone } from 'react-icons/md';
import { Address } from 'shared/types/account';
import FormattedAddressSummary from '~/components/furniturechoice/formatted-address-summary';

interface AddressCardProps {
  headline: string;
  address: Address;
}

export default function AddressCard({ headline, address }: AddressCardProps): JSX.Element {
  return (
    <section className="flex flex-col gap-y-3 border-y border-solid border-grey-3 py-8">
      <h3 className="font-sans font-semibold">{headline}</h3>
      {address && (
        <div className="surface-grey-1 flex flex-col gap-y-3 rounded-md p-4">
          <div className="flex items-center justify-between gap-x-2">
            <p className="font-bold" data-testid="name">
              {`${address.salutation ?? ''} ${address?.firstName} ${address.lastName ?? ''}`}
            </p>
          </div>

          <FormattedAddressSummary address={address as Address} icon={<MdOutlineLocationOn />} />

          <div className="flex items-center gap-x-2">
            <MdPhoneIphone className="text-20 text-grey-5" />

            <div className="flex flex-col gap-y-2">
              <p className="text-grey-5">{address?.phone}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
