import clsx from 'clsx';
import { BsBoxSeam } from 'react-icons/bs';
import { MdPassword, MdPersonOutline } from 'react-icons/md';
import { Account } from 'shared/types/account';
import AccountSignout from '~/components/furniturechoice/account-signout';
import AccountWelcomeHeader from '~/components/furniturechoice/account-welcome-header';
import Link from '~/components/furniturechoice/link';
import { useFormat } from '~/helpers/hooks/useFormat';

interface WelcomeContentProps {
  account: Account;
}

export default function WelcomeContent({ account }: WelcomeContentProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="container mx-auto flex flex-col justify-center">
      <AccountWelcomeHeader account={account} />

      <nav>
        <ul className={clsx('lg:flex lg:justify-between lg:border-b lg:border-solid lg:border-grey-3')}>
          <li className="flex-1 border-b border-solid border-grey-3 lg:border-none">
            <Link className="flex w-full items-center gap-x-2 p-4 lg:justify-center" href="/account/orders">
              <BsBoxSeam className="text-24" />
              {formatMessage({ id: 'tastics.account.account-content-manager.my_orders' })}
            </Link>
          </li>

          <li className="flex-1 border-b border-solid border-grey-3 lg:border-none">
            <Link className="flex w-full items-center gap-x-2 p-4 lg:justify-center" href="/account/details">
              <MdPersonOutline className="text-24" />
              {formatMessage({ id: 'tastics.account.account-content-manager.my_details' })}
            </Link>
          </li>

          <li className="flex-1 border-b border-solid border-grey-3 lg:border-none">
            <Link className="flex w-full items-center gap-x-2 p-4 lg:justify-center" href="/account/change-password">
              <MdPassword className="text-24" />
              {formatMessage({ id: 'tastics.account.account-content-manager.change_password' })}
            </Link>
          </li>
        </ul>
      </nav>

      <AccountSignout className="flex w-full items-center gap-x-1 p-4 text-grey-5 lg:hidden" />
    </div>
  );
}
