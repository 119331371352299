import { MdChevronRight } from 'react-icons/md';
import Link from '~/components/furniturechoice/link';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function WishlistEmpty() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div
      className="container col-span-full flex flex-col justify-center gap-y-2 text-center font-normal"
      data-testid="empty-state"
    >
      <p className="text-grey-5">
        {formatMessage({ id: 'tastics.wishlist.wishlist-content-manager.add_an_item_to_your_wishlist' })}
      </p>

      <Link
        ga4_event_name="continue_shopping"
        ga4_event_param_1_name="element"
        ga4_event_param_1_value="Body"
        className="text-salmon-1"
        href="/"
      >
        {formatMessage({ id: 'tastics.wishlist.wishlist-content-manager.continue_shopping' })}
        <MdChevronRight className="inline" />
      </Link>
    </div>
  );
}
