interface HeaderProps {
  contentHeadline: string;
  contentHeadlineIcon: JSX.Element;
}

export default function Header({ contentHeadline, contentHeadlineIcon }: HeaderProps): JSX.Element {
  return (
    <div className="container mb-5 lg:mb-8">
      <header className="border-b border-solid border-grey-3 py-4 lg:hidden">
        <div className="flex items-center">
          <h1 className="flex flex-1 items-center justify-center gap-x-2 pr-[30px] font-sans text-24 font-bold">
            <span className="text-30">{contentHeadlineIcon}</span>
            {contentHeadline}
          </h1>
        </div>
      </header>
    </div>
  );
}
