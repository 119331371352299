import { FormEvent, useContext, useState } from 'react';
import { useRouter } from 'next/navigation';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sdk } from '~/sdk';

interface FormProps {
  token: string;
}

export default function Form({ token }: FormProps): JSX.Element {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress, isProgressIndicatorVisible } = useContext(ProgressIndicatorContext);
  const [passwordInput, setPasswordInput] = useState('');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData) as {
      newPassword: string;
      confirmNewPassword: string;
    };

    sdk.defaultConfigure('en');
    const response = await executeWithProgress(() =>
      sdk.callAction({
        actionName: 'account/setPasswordAndVerify',
        payload: {
          password: payload.newPassword,
          token,
        },
      }),
    );

    if (!response.isError) {
      router.replace('/sign-in');
    }
  }

  return (
    <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
      <h2 className="hidden font-sans text-20 font-bold lg:block">
        {formatMessage({ id: 'tastics.account.verification-content-manager.set_password' })}
      </h2>
      <div className="flex flex-col gap-y-3 lg:w-1/2">
        <FNCInput
          type="password"
          name="newPassword"
          onEveryChange={(e) => setPasswordInput(e.target.value)}
          label={formatMessage({ id: 'tastics.account.change-password-content-manager.new_password' })}
          disabled={isProgressIndicatorVisible}
          required
        />
        <FNCInput
          type="password"
          name="confirmNewPassword"
          label={formatMessage({ id: 'tastics.account.change-password-content-manager.confirm_new_password' })}
          required
          pattern={`^${passwordInput}$`}
          patternFeedback={formatMessage({
            id: 'tastics.account.change-password-content-manager.password_mismatch_error',
          })}
          disabled={isProgressIndicatorVisible}
        />

        <FNCButton className="surface-green-2 rounded-md" type="submit">
          {formatMessage({ id: 'tastics.account.change-password-content-manager.save_changes' })}
        </FNCButton>
      </div>
    </form>
  );
}
