import { Cart, ShippingMethod } from 'shared/types/cart';

export default function getValidShippingMethods(cart: Cart, isCartItemsOnStock: boolean): ShippingMethod[] {
  const priorityOrderedShippingMethods = ['D2HMAIN', 'PANTHER', 'D2HSOFA', 'PF'];
  const cartStockStatus = isCartItemsOnStock ? 'in-stock' : 'out-stock';
  const cartDispatchService = priorityOrderedShippingMethods.find(
    (key) => !!cart.lineItems?.find((item) => item.variant.attributes?.['logistics_dispatch-service'].key === key),
  ) as string;
  const shippingMethods =
    cart.availableShippingMethods?.filter(
      (shippingMethod) =>
        shippingMethod.custom.cartDispatchService.includes(cartDispatchService) &&
        shippingMethod.custom.cartStockStatus === cartStockStatus,
    ) || [];

  return shippingMethods.sort((a, b) => a.custom.orderHint - b.custom.orderHint);
}
