import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { format } from 'date-fns';
import { BsBoxSeam } from 'react-icons/bs';
import { MdCheck, MdPrint } from 'react-icons/md';
import { Address } from 'shared/types/account';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import { FNCOrder } from 'shared/types/furniturechoice/order';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { getPriceSummary } from '~/helpers/furniturechoice/displayPriceHelper';
import { useFormat } from '~/helpers/hooks/useFormat';
import AddressCard from './address';
import LineItems from './line-items';
import PaymentMethod from './payment-method';

interface OrderProps {
  order: FNCOrder;
}

export default function Order({ order }: OrderProps): JSX.Element {
  const { locale } = useParams();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const totalLineItemQuantity = order.lineItems?.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.count as number),
    0,
  );

  const { transaction, subtotalAmount, discountAmount } = getPriceSummary(order);
  const subtotalCurrency = CurrencyHelpers.formatForCurrency(
    subtotalAmount,
    locale,
    transaction.subtotal.currencyCode,
    transaction.subtotal.fractionDigits,
  );
  const discountCurrency = CurrencyHelpers.formatForCurrency(
    discountAmount,
    locale,
    transaction.subtotal.currencyCode,
    transaction.subtotal.fractionDigits,
  );

  return (
    <section
      className={clsx(
        'grid grid-cols-1 gap-y-5',
        '[grid-template-areas:"header""nav""summary""sub-details"]',
        'lg:grid-cols-[1fr_max(343px)] lg:gap-x-6 lg:[grid-template-areas:"header_header""summary_nav""summary_sub-details"]',
      )}
    >
      <header className="flex items-center justify-between [grid-area:header]">
        <h2 className="text-center font-sans text-20 font-semibold lg:text-left">
          {formatMessage({ id: 'tastics.account.order-detail-content-manager.my_order_details' })}
        </h2>

        <button
          onClick={() => {
            window.print();
          }}
          className="flex items-center gap-x-1 text-14 text-grey-4"
          type="button"
        >
          {formatMessage({ id: 'tastics.account.order-detail-content-manager.print_this_order' })}
          <MdPrint />
        </button>
      </header>

      <nav className="surface-grey-1 rounded-md px-4 py-6 [grid-area:nav]">
        <ul className="flex flex-col gap-y-2">
          <li>
            <FNCLinkButton
              className="surface-salmon-1 min-h-[45px] font-normal"
              href="https://help.furniturechoicelab.com/hc/en-gb/categories/4429681273233-Deliveries-and-collections"
            >
              {formatMessage({ id: 'tastics.account.order-detail-content-manager.deliveries_and_collections' })}
            </FNCLinkButton>
          </li>

          <li>
            <FNCLinkButton
              className="surface-salmon-1 min-h-[45px] font-normal"
              href="https://help.furniturechoicelab.com/hc/en-gb/categories/5146833937169-Returns-amends-or-cancellations"
            >
              {formatMessage({ id: 'tastics.account.order-detail-content-manager.returns_amends_or_cancellations' })}
            </FNCLinkButton>
          </li>

          <li>
            <FNCLinkButton
              className="surface-salmon-1 min-h-[45px] font-normal"
              href="https://help.furniturechoicelab.com/hc/en-gb/categories/5147559786897-Damaged-faulty-or-missing-parts"
            >
              {formatMessage({ id: 'tastics.account.order-detail-content-manager.damaged_faulty_or_missing_parts' })}
            </FNCLinkButton>
          </li>

          <li>
            <FNCLinkButton
              className="surface-salmon-1 min-h-[45px] font-normal"
              href="https://help.furniturechoicelab.com/hc/en-gb/categories/5146917954833-Product-support-and-care-guides"
            >
              {formatMessage({ id: 'tastics.account.order-detail-content-manager.product_support_and_care_guides' })}
            </FNCLinkButton>
          </li>
        </ul>
      </nav>
      <div className="print-area">
        <div className="flex flex-col gap-y-5 [grid-area:summary]">
          <section className="surface-grey-1 rounded-md px-4">
            <div className="flex flex-col gap-y-1 border-b-2 border-solid border-white py-5">
              <div className="flex items-center justify-between">
                <p className="font-semibold">
                  {formatMessage({ id: 'tastics.account.order-detail-content-manager.order_no' })}:
                </p>

                <p className="text-grey-4">{order.orderNumber}</p>
              </div>

              <div className="flex items-center justify-between">
                <p className="font-semibold">
                  {formatMessage({ id: 'tastics.account.order-detail-content-manager.order_date' })}:
                </p>

                <p className="text-grey-4">{order.createdAt ? format(order.createdAt as Date, 'dd/MM/yyyy') : ''}</p>
              </div>
            </div>

            <div className="flex flex-col gap-y-5 border-b-2 border-solid border-white py-5">
              <p className="text-14 text-grey-4">
                {`${totalLineItemQuantity} ${formatMessage({
                  id: 'tastics.account.order-detail-content-manager.items_in_this_order',
                })}`}
              </p>

              <LineItems lineItems={order.lineItems as FNCLineItem[]} />
            </div>

            <div className="flex flex-col gap-y-1 border-b-2 border-solid border-white py-5">
              <div className="flex items-center justify-between">
                <p className="text-14 text-grey-5">
                  {formatMessage({ id: 'tastics.account.order-detail-content-manager.order_subtotal' })}:
                </p>

                <p className="font-semibold">{subtotalCurrency}</p>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-14 text-grey-5">
                  {formatMessage({ id: 'tastics.account.order-detail-content-manager.delivery' })}:
                </p>

                <p className="font-semibold">
                  {CurrencyHelpers.formatForCurrency(
                    order.shippingInfo?.price ?? 0,
                    locale,
                    order.shippingInfo?.price?.currencyCode,
                    order.shippingInfo?.price?.fractionDigits,
                  )}
                </p>
              </div>

              {discountAmount > 0 && (
                <div className="flex items-center justify-between">
                  <p className="text-14 text-grey-5">
                    {formatMessage({ id: 'tastics.account.order-detail-content-manager.discount' })}:
                  </p>

                  <p className="font-semibold">{discountCurrency}</p>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-y-1 border-b-2 border-solid border-white py-5">
              <div className="flex items-center justify-between">
                <p className="text-14">
                  <span className="font-semibold">
                    {formatMessage({ id: 'tastics.account.order-detail-content-manager.total' })}
                  </span>{' '}
                  <span className="text-grey-5">
                    {formatMessage({ id: 'tastics.account.order-detail-content-manager.(including_vat)' })}
                  </span>
                </p>

                <p className="font-semibold">
                  {CurrencyHelpers.formatForCurrency(
                    order.sum ?? 0,
                    locale,
                    order.sum?.currencyCode,
                    order.sum?.fractionDigits,
                  )}
                </p>
              </div>
            </div>
          </section>

          <section className="surface-grey-1 rounded-md px-4">
            <div className="flex flex-col gap-y-3 py-5 lg:gap-y-5">
              <div className="flex gap-x-3 text-green-2">
                {order.shippingInfo && (
                  <>
                    <MdCheck className="min-w-[22px] text-22" />
                    <p className="font-semibold leading-5">{order.shippingInfo?.custom.uiDescription}</p>
                  </>
                )}
              </div>

              <div className="flex gap-x-3">
                <BsBoxSeam className="min-w-[22px] text-22 text-grey-5" />

                <div className="flex flex-col gap-y-1">
                  <p className="text-12 text-grey-5">
                    {formatMessage({ id: 'tastics.account.order-detail-content-manager.delivery_date' })}
                  </p>

                  <p className="text-12 font-semibold leading-5">
                    {order.custom?.deliveryDate ? format(order.custom?.deliveryDate, 'dd/MM/yyyy') : '-'}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="mt-2.5 [grid-area:sub-details]">
          <AddressCard
            headline={formatMessage({ id: 'tastics.account.order-detail-content-manager.billing_address' })}
            address={order.billingAddress as Address}
          />

          <AddressCard
            headline={formatMessage({ id: 'tastics.account.order-detail-content-manager.delivery_address' })}
            address={order.shippingAddress as Address}
          />
          {order.metafields && order.metafields.paymentMethodInfo && (
            <PaymentMethod paymentMethodInfo={order.metafields.paymentMethodInfo} />
          )}
        </div>
      </div>
    </section>
  );
}
