'use client';

import { BsBoxSeam } from 'react-icons/bs';
import { Account } from 'shared/types/account';
import { Order } from 'shared/types/cart';
import AccountContainer from '~/components/furniturechoice/account-container';
import AccountSignout from '~/components/furniturechoice/account-signout';
import { useAccount } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';
import OrderList from './order-list';

interface OrdersContentManagerProps {
  accountWithOrders: DataSourceV2<{
    account: Account;
    orders: Order[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    historicalOrders: any;
  }>;
}

export default function OrdersContentManager({ data: { accountWithOrders } }: TasticProps<OrdersContentManagerProps>) {
  const { account, orders, historicalOrders } = accountWithOrders.dataSource;
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const accountManager = useAccount();
  const accountData = accountManager.account || account;

  const allOrders = [...orders, ...historicalOrders];

  return (
    <section className="standard-section-padding">
      <AccountContainer
        account={accountData}
        contentHeadline={formatMessage({ id: 'tastics.account.orders-content-manager.my_orders' })}
        contentHeadlineIcon={<BsBoxSeam />}
      >
        <OrderList orders={allOrders} />
        <AccountSignout className="flex w-full items-center gap-x-1 p-4 text-grey-5 lg:hidden" />
      </AccountContainer>
    </section>
  );
}
