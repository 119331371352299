'use client';

import { useState } from 'react';
import clsx from 'clsx';
import { FNCProduct } from 'shared/types/product/Product';
import Accordion from '~/components/furniturechoice/accordion';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import CommercetoolsImage from '~/components/furniturechoice/commercetools-image';
// import ContentfulImage from '~/components/furniturechoice/contentful-image';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import useTestExample from '~/helpers/hooks/furniturechoice/use-test-example';
import { DataSourceV2 } from '~/types/datasource';

interface TestExampleProps {
  headline: string;
  supportingText?: string;
  product: DataSourceV2<FNCProduct>;
  media: ImageProps;
}

export default function TestExample({
  data: { headline, supportingText, product, media },
}: TasticProps<TestExampleProps>) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const testHookMessage = useTestExample();

  console.log(product);

  const testAccordionContents = [
    {
      headingTag: 'h1',
      headingText: 'Test Accordion 1',
      panel: `If you go over your organisations or user limit, a member of the team will reach out about bespoke pricing. In the meantime, our collaborative features won't appear in accounts or users that are over the 100-account or 1,000-user limit.`,
      isExpanded: false,
    },
    {
      headingTag: 'h1',
      headingText: 'Test Accordion 2',
      panel: ``,
      isExpanded: false,
    },
    {
      headingTag: 'h1',
      headingText: 'Test Accordion 3',
      panel: `If you go over your organisations or user limit, a member of the team will reach out about bespoke pricing. In the meantime, our collaborative features won't appear in accounts or users that are over the 100-account or 1,000-user limit.`,
      isExpanded: false,
    },
  ];

  return (
    <section>
      <button
        className="rounded border border-grey-4 p-4"
        data-testid="cta-button"
        onClick={() => setIsVisible(!isVisible)}
      >
        Click Me
      </button>

      <div className={clsx('slide-right-initial container', { 'slide-right-enter': isVisible })} data-testid="content">
        <h2>{headline}</h2>
        {supportingText && <p data-testid="supporting-text">{supportingText}</p>}
        <p data-testid="test-hook-message">{testHookMessage}</p>
        <p data-testid="product-name">{product.dataSource.name}</p>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div>
            <h3>Commercetools Image:</h3>

            <div className="aspect-video lg:aspect-square">
              {product.dataSource.variants[0].images?.[0] && (
                <CommercetoolsImage
                  className="w-full"
                  src={product.dataSource.variants[0].images?.[0]}
                  colSpanPercentLg={0.5}
                />
              )}
            </div>
          </div>

          {/* <div>
            <h3>Contentful Image:</h3>

            <div className="aspect-video lg:aspect-square">
              <ContentfulImage
                className="w-full"
                src={product.dataSource.metafields.testImage.fields.file.url}
                colSpanPercentLg={0.5}
                aspectRatio={16 / 9}
                aspectRatioLg={1 / 1}
              />
            </div>
          </div> */}

          <div>
            <h3>CoFe Image:</h3>
            <div className="aspect-video lg:aspect-square">
              <CoFeImage
                className="w-full"
                asset={media.media}
                colSpanPercentLg={0.5}
                aspectRatio={16 / 9}
                aspectRatioLg={1 / 1}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Accordion contents={testAccordionContents} />
      </div>
    </section>
  );
}
