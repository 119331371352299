import { Order } from 'shared/types/cart';
import { useFormat } from '~/helpers/hooks/useFormat';
import OrderListItem from './order-list-item';

interface OrderListProps {
  orders: Order[];
}

export default function OrderList({ orders }: OrderListProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <section className="flex flex-col gap-y-4">
      <h2 className="text-center font-sans text-20 lg:text-left">
        <span className="font-bold">
          {formatMessage({ id: 'tastics.account.orders-content-manager.order_history' })}
        </span>
        <span className="font-light" data-testid="order-history-headline-count">{` (${orders.length})`}</span>
      </h2>

      {orders.length ? (
        <ul className="flex flex-col gap-y-2">
          {orders.map((order) => (
            <OrderListItem key={order.orderId} order={order} />
          ))}
        </ul>
      ) : (
        <div className="rounded bg-grey-2 p-4 font-semibold text-grey-6">
          {formatMessage({ id: 'tastics.account.orders-content-manager.no_orders_found' })}
        </div>
      )}
    </section>
  );
}
