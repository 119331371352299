import { useCallback, useState } from 'react';
import clsx from 'clsx';
import { MdChevronRight } from 'react-icons/md';
import { Account } from 'shared/types/account';
import { Cart } from 'shared/types/cart';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import FNCRadio from '~/components/furniturechoice/fnc/fnc-radio';
import FNCSelect from '~/components/furniturechoice/fnc/fnc-select';
import { useFormat } from '~/helpers/hooks/useFormat';
import { billingCountries, defaultBillingCountry } from '~/static/billing-countries';
import { useAddressHook } from '../use-loqate';

interface AddressProps {
  type: 'shipping' | 'billing';
  cart: Cart;
  account?: Account;
  isSeparateShippingContactNumberAllowed: boolean;
}

export default function Address({
  type,
  cart,
  account,
  isSeparateShippingContactNumberAllowed,
}: AddressProps): JSX.Element {
  const [hasSeparateShippingContactNumber, setHasSeparateShippingContactNumber] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const defaultAccountAddress = account?.addresses?.find((address) => address.isDefaultBillingAddress);
  const defaultCartAddress = type === 'billing' ? cart.billingAddress : cart.shippingAddress;
  const defaultAddress = Array.isArray(defaultCartAddress) ? defaultAccountAddress : defaultCartAddress;
  const [isExpanded, setIsExpanded] = useState<boolean>(!!defaultAddress);
  const { suggestedAddresses, address, getSuggestedAddresses, getAddress } = useAddressHook();

  const handleTypeAddress = useCallback(
    async (
      event: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
      id: string | null = null,
    ) => {
      const postcode = (event.target as HTMLInputElement).value;
      const container = id || '';

      try {
        await getSuggestedAddresses(postcode, container);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    },
    [getSuggestedAddresses],
  );

  const handleAddressClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      event.preventDefault();

      if (id.includes('gb-rm')) {
        // If 'gb-rm' is in the string, perform the same action as handleTypeAddress
        const postcode = (event.currentTarget as HTMLButtonElement).innerText;
        handleTypeAddress({ target: { value: postcode } } as React.ChangeEvent<HTMLInputElement>, id);
      } else {
        // Otherwise, fetch the address details using the id and set the selected address
        try {
          await getAddress(id);
          getSuggestedAddresses('');
          setIsExpanded(true);
        } catch (error) {
          console.error('Error fetching address details:', error);
        }
      }
    },
    [handleTypeAddress, getAddress, getSuggestedAddresses],
  );

  return (
    <div className="surface-grey-1 flex flex-col gap-y-3 rounded-md px-3 py-6" data-testid={`${type}-address`}>
      <fieldset>
        <div className="surface-white relative flex h-[63px] flex-col gap-y-1 rounded-md border border-solid border-grey-7">
          <legend className="absolute left-3 top-2 text-12 text-grey-7">
            {formatMessage({ id: 'tastics.checkout.information.information-content-manager.salutation' })}
          </legend>

          <div className="mt-auto flex items-center gap-4 px-4 py-2">
            <FNCRadio
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.miss' })}
              name={`${type}.salutation`}
              value="Miss"
              required
              defaultChecked={defaultAddress?.salutation === 'Miss'}
            />

            <FNCRadio
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.mr' })}
              name={`${type}.salutation`}
              value="Mr."
              required
              defaultChecked={defaultAddress?.salutation === 'Mr.'}
            />

            <FNCRadio
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.mrs' })}
              name={`${type}.salutation`}
              value="Mrs."
              required
              defaultChecked={defaultAddress?.salutation === 'Mrs.'}
            />

            <FNCRadio
              label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.ms' })}
              name={`${type}.salutation`}
              value="Ms."
              required
              defaultChecked={defaultAddress?.salutation === 'Ms.'}
            />
          </div>
        </div>
      </fieldset>

      <FNCInput
        type="text"
        name={`${type}.firstName`}
        required
        label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.first_name' })}
        defaultValue={defaultAddress?.firstName}
      />

      <FNCInput
        type="text"
        name={`${type}.lastName`}
        required
        label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.last_name' })}
        defaultValue={defaultAddress?.lastName}
      />

      {type === 'billing' && (
        <FNCInput
          type="email"
          name={`${type}.email`}
          required
          label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.email' })}
          defaultValue={cart.email || account?.email}
        />
      )}

      <FNCInput
        type="tel"
        name={`${type}.phone`}
        required
        label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.contact_number' })}
        defaultValue={defaultAddress?.phone}
      />

      {isSeparateShippingContactNumberAllowed && (
        <>
          {hasSeparateShippingContactNumber && (
            <FNCInput
              data-testid="shipping-contact-number-field"
              type="tel"
              name="shipping.phone"
              label={formatMessage({
                id: 'tastics.checkout.information.information-content-manager.delivery_contact_number',
              })}
              defaultValue={cart.shippingAddress?.phone}
            />
          )}

          {!hasSeparateShippingContactNumber && (
            <button
              className="mt-1 text-left text-12 text-grey-7"
              data-testid="separate-shipping-contact-number-cta-button"
              type="button"
              onClick={() => setHasSeparateShippingContactNumber(true)}
            >
              {`${formatMessage({
                id: 'tastics.checkout.information.information-content-manager.add_a_different_number_for_delivery',
              })} >`}
            </button>
          )}
        </>
      )}

      {!isExpanded && (
        <>
          <FNCInput
            type="text"
            name={`${type}.typeAhead`}
            required
            label={formatMessage({
              id: 'tastics.checkout.information.information-content-manager.type_your_address',
            })}
            onValidChange={(e) => handleTypeAddress(e)}
          />

          {suggestedAddresses.length > 0 && (
            <div
              id={`${type}.postcodeDropdown`}
              className="relative z-10 -mt-4 rounded-sm border border-grey-3 bg-white text-14 leading-[18px]"
            >
              {suggestedAddresses.map((address, index) => (
                <button
                  key={index}
                  className={clsx(
                    'flex w-full items-center justify-between border-b border-grey-3 p-2 text-left last:border-b-0',
                    'hover:bg-grey-1',
                  )}
                  onClick={(e) => handleAddressClick(e, address.Id)}
                >
                  <span>
                    {address.Text} {address.Description ? address.Description : ''}
                  </span>
                  {/Addresses/.test(address.Description) && <MdChevronRight className="w-[22px] fill-grey-7 text-20" />}
                </button>
              ))}
            </div>
          )}
        </>
      )}

      {isExpanded && (
        <>
          <FNCInput
            data-testid="street-field"
            type="text"
            name={`${type}.streetName`}
            required
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.address_1' })}
            defaultValue={address?.Street || defaultAddress?.streetName || ''}
          />
          <FNCInput
            data-testid="house-name-field"
            type="text"
            name={`${type}.streetNumber`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.address_2' })}
            defaultValue={address?.CustomItem || defaultAddress?.streetNumber || ''}
          />
          <FNCInput
            data-testid="locality-field"
            type="text"
            name={`${type}.additionalAddressInfo`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.address_3' })}
            defaultValue={address?.District || defaultAddress?.additionalAddressInfo || ''}
          />
          <FNCInput
            data-testid="town-or-city-field"
            type="text"
            name={`${type}.city`}
            required
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.town_or_city' })}
            defaultValue={address?.City || defaultAddress?.city || ''}
          />
          <FNCInput
            data-testid="county-field"
            type="text"
            name={`${type}.region`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.county' })}
            defaultValue={address?.ProvinceName || defaultAddress?.region || ''}
          />
          <FNCSelect
            data-testid="country-field"
            name={`${type}.country`}
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.country' })}
            disabled={type === 'shipping'}
            defaultValue={defaultBillingCountry}
          >
            {Object.keys(billingCountries).map((key) => (
              <option key={key} value={key}>
                {billingCountries[key as keyof typeof billingCountries]}
              </option>
            ))}
          </FNCSelect>
          <FNCInput
            type="text"
            name={`${type}.postalCode`}
            required
            label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.postcode' })}
            defaultValue={address?.PostalCode || defaultAddress?.postalCode || ''}
          />
          <button
            className="mt-1 text-12 font-bold text-grey-4 lg:ml-2 lg:self-start"
            data-testid="reset-address-button"
            type="reset"
            onClick={() => setIsExpanded(false)}
          >
            {formatMessage({ id: 'tastics.checkout.information.information-content-manager.reset_address' })}
          </button>
        </>
      )}

      {!isExpanded && (
        <button
          className="mt-1 text-left text-12 text-grey-7"
          data-testid="enter-address-button"
          type="button"
          onClick={() => setIsExpanded(true)}
        >
          {`${formatMessage({ id: 'tastics.checkout.information.information-content-manager.enter_address' })} >`}
        </button>
      )}
    </div>
  );
}
