import { FormEvent, useContext, useState } from 'react';
import { Account } from 'shared/types/account';
import { Cart } from 'shared/types/cart';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCCheckbox from '~/components/furniturechoice/fnc/fnc-checkbox';
import FNCRadio from '~/components/furniturechoice/fnc/fnc-radio';
import Link from '~/components/furniturechoice/link';
import PaymentMethod from '~/components/furniturechoice/payment-methods';
import DigicertIcon from '~/components/icons/digicert';
import { useCart } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import useMailchimp from '~/helpers/hooks/furniturechoice/use-mailchimp';
import { useFormat } from '~/helpers/hooks/useFormat';
import isSameBillingAndShippingAddress from '~/helpers/utils/furniturechoice/is-same-billing-and-shipping-address';
import { sendGA4Event } from '~/utils/send-ga4-event';
import Address from './address';
import PopupModal from './popup-modal';
import setCartAddresses from './set-cart-addresses';
import validatePostcode from './utils/validate-postcode';
import validateServiceablePostcode from './utils/validate-serviceable-postcode';

interface FormProps {
  cart: Cart;
  account?: Account;
}

export default function Form({ cart, account }: FormProps): JSX.Element {
  const [isSeparateShippingContactNumberAllowed, setIsSeparateShippingContactNumberAllowed] = useState<boolean>(true);
  const [hasSeparateShippingAddress, setHasSeparateShippingAddress] = useState<boolean>(
    !isSameBillingAndShippingAddress(cart.billingAddress, cart.shippingAddress),
  );
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const cartManager = useCart();
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const mailchimp = useMailchimp('newsletter');
  const [showDeliveryErrorPopup, setShowDeliveryErrorPopup] = useState<boolean>(false);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);

    try {
      await executeWithProgress(async () => {
        if (payload['isSigningUpToNewsletter'] === 'yes' && !mailchimp.subscriptionStatus.data) {
          await mailchimp.subscribe(account ? undefined : (payload['billing.email'] as string));
        } else if (payload['isSigningUpToNewsletter'] === 'no' && mailchimp.subscriptionStatus.data && account) {
          // Send GA4 event
          sendGA4Event('newsletter', 'interaction_type', 'Unsubscribe');
          // Unsubscribe from newsletter
          await mailchimp.unsubscribe();
        }

        if (payload['isRegisteringAccount'] === 'yes') {
          sessionStorage.setItem('isRegisteringAccount', 'yes');
          sessionStorage.setItem('registerAccountDetails', JSON.stringify(payload));

          sendGA4Event('sign_up', 'method', 'Checkout');
        } else {
          if (sessionStorage.getItem('isRegisteringAccount')) {
            sessionStorage.removeItem('isRegisteringAccount');
            sessionStorage.removeItem('registerAccountDetails');
          }
        }

        const shippingPostalCode = hasSeparateShippingAddress
          ? (payload['shipping.postalCode'] as string)
          : (payload['billing.postalCode'] as string);

        const isValidPostcode = validatePostcode(shippingPostalCode);
        const isNonServiceablePostcode = validateServiceablePostcode(shippingPostalCode);

        if (!isValidPostcode || isNonServiceablePostcode) {
          setShowDeliveryErrorPopup(true);
        } else if (isValidPostcode || !isNonServiceablePostcode) {
          await setCartAddresses(cartManager, payload);
          sendGA4Event('select_delivery_address');
          location.assign('/checkout/shipping');
        }
      });
    } catch {}
  }

  function handleDeliverToDifferentAddressClick() {
    setHasSeparateShippingAddress(!hasSeparateShippingAddress);
    setIsSeparateShippingContactNumberAllowed(!isSeparateShippingContactNumberAllowed);
  }

  return (
    <>
      <form className="flex flex-col gap-y-5 lg:order-first lg:ml-auto lg:max-w-[471px]" onSubmit={handleSubmit}>
        <h2 className="font-sans text-20 font-bold text-black">
          {formatMessage({
            id: 'tastics.checkout.information.information-content-manager.billing_and_delivery_address',
          })}
        </h2>

        <Address
          type="billing"
          cart={cart}
          account={account}
          isSeparateShippingContactNumberAllowed={isSeparateShippingContactNumberAllowed}
        />

        <div className="mt-1 flex flex-col gap-y-4">
          <FNCCheckbox
            className="px-3"
            data-testid="deliver-to-different-address-checkbox"
            label={formatMessage({
              id: 'tastics.checkout.information.information-content-manager.deliver_to_a_different_address',
            })}
            onChange={() => handleDeliverToDifferentAddressClick()}
          />

          {hasSeparateShippingAddress && (
            <Address type="shipping" cart={cart} account={account} isSeparateShippingContactNumberAllowed={false} />
          )}

          <FNCCheckbox
            className="px-3"
            label={formatMessage({
              id: 'tastics.checkout.information.information-content-manager.create_an_account_to_save_your_details',
            })}
            name="isRegisteringAccount"
            value="yes"
          />
        </div>

        <fieldset>
          <div className="surface-grey-1 mt-1 grid grid-cols-[1fr_130px] gap-x-4 px-4 py-5 lg:gap-x-7 lg:py-6">
            <legend className="text-14 leading-[18px] text-grey-5">
              {formatMessage({ id: 'tastics.checkout.information.information-content-manager.sign_up_newsletter' })}
            </legend>
            <div className="flex items-center">
              {!mailchimp.subscriptionStatus.isLoading && (
                <>
                  <FNCRadio
                    className="flex-1"
                    label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.yes' })}
                    name="isSigningUpToNewsletter"
                    value="yes"
                    required
                    defaultChecked={mailchimp.subscriptionStatus.data}
                  />

                  <FNCRadio
                    className="flex-1"
                    label={formatMessage({ id: 'tastics.checkout.information.information-content-manager.no' })}
                    name="isSigningUpToNewsletter"
                    value="no"
                    required
                    defaultChecked={!mailchimp.subscriptionStatus.data}
                  />
                </>
              )}
            </div>
          </div>
        </fieldset>

        <p className="px-1 text-12 leading-[18px] text-grey-5">
          <span>
            {formatMessage({ id: 'tastics.checkout.information.information-content-manager.disclaimer_prefix' })}
          </span>

          <Link className="text-salmon-4" href="/customer-service/terms-and-conditions/">
            {formatMessage({ id: 'tastics.checkout.information.information-content-manager.terms_and_condition' })}
          </Link>

          <span> {formatMessage({ id: 'tastics.checkout.information.information-content-manager.and' })} </span>

          <Link className="text-salmon-4" href="/customer-service/privacy-policy/">
            {formatMessage({ id: 'tastics.checkout.information.information-content-manager.privacy_policy' })}
          </Link>
        </p>

        <FNCButton className="surface-green-2 rounded-sm lg:mt-4" type="submit">
          {formatMessage({ id: 'tastics.checkout.information.information-content-manager.continue' })}
        </FNCButton>

        <div className="flex items-center">
          <PaymentMethod className="flex gap-2" />
          <DigicertIcon className="ml-auto" />
        </div>
      </form>
      <PopupModal
        isOpen={showDeliveryErrorPopup}
        onModalClose={() => setShowDeliveryErrorPopup(false)}
        title={formatMessage({
          id: 'tastics.checkout.information.information-content-manager.delivery_zone_error_title',
        })}
      >
        {formatMessage({ id: 'tastics.checkout.information.information-content-manager.delivery_zone_error_message' })}
      </PopupModal>
    </>
  );
}
